/*
 * login-promentum-link.js - Adds link to promentum website on login page
 */
'use strict';

jQuery(document).ready(function() {
    Sao.Session.login_dialog_promentum_link = Sao.Session.login_dialog;
    Sao.Session.login_dialog = function() {
        var dialog = Sao.Session.login_dialog_promentum_link();
        var footer = jQuery('<div/>', {
            'class': 'login-footer'
        }).append(jQuery('<div/>', {
            'class': 'login-logo'
        })).append(jQuery('<p/>').text("Powered by ").append(jQuery('<a/>', {
            'href': 'https://www.promentum.co.uk/',
            'class': 'login-link'
        }).text("Promentum")));
        jQuery.ajax({
            'url': '/procentral-version.txt'
        }).done(function(version){
            var parts = version.split('\n');
            var series = parts[0];
            var commit = parts[2].substring(0, 8);
            var branch = "";
            if (parts[1] != 'master') {
                branch = "-" + parts[1];
            }
            jQuery('<p/>', {
                'class': 'version'
            }).text(
                "Version " + series + "." + commit + branch
            ).insertAfter(footer.children()[0]);
        });
        dialog.modal.append(footer);
        return dialog;
    };
});
